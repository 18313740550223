.dropzone-ui-extra {
  border: none !important;
  height: 100% !important;
  max-height: 100% !important;
  min-height: 10px !important;
  padding: 0px 15px;
}

.dz-ui-footer {
  display: none !important;
  width: 50% !important;
  align-items: initial !important;
  padding: 0 !important;
  border-top: none !important;
  border-radius: 4em;
}

.dz-ui-header {
  margin-top: 10px;
}

.dz-ui-label {
  font-size: 18px !important;
  font-family: "Inter", sans-serif !important;
  letter-spacing: 0 !important;
}
.dz-ui-label::after {
  content: "browse";
  color: #0066ff;
}

.pre-wrap-list-item {
  white-space: pre-wrap;
  overflow: hidden;
}

.buttons {
  width: 235px;
  margin: auto;
}

.buttons__text {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.08em;
  margin: 0px;
}

.dropzone-ui.clickable {
  background-color: #e9ecf0;
}

.grid__titles {
  display: flex;
  justify-content: center;
  height: 40px;
  margin-bottom: 10px !important;
}

h2 {
  color: #0066ff;
  font-size: 24px;
  font-weight: 600;
  margin: 0px;
}

.emptyMsgAndLoading {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
}

.listItem__name {
  overflow: hidden !important;
}

.file-item-list-container.file-item-list-no-scroll {
  overflow: scroll !important;
  min-height: 80px !important;
}
